import React from "react";

import { FaSearch } from "react-icons/fa";
import { FaUser } from "react-icons/fa6";
import { FiShoppingCart } from "react-icons/fi";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import Hamburger from "hamburger-react";
import Modal from "react-modal";
import Ticker from "react-ticker";

import { CartContext } from "../context/CartContext";
import { useCategories } from "../hooks/useCategories";
import Cart from "./cart/Cart";
import useGlobal from "../store";

import delta9gummies from "../img/bay-smokes/delta9gummies.png";
import sthca from "../img/bay-smokes/thca-flower.png";
import thacaCarts from "../img/bay-smokes/thaca-carts.png";

export default function Navbar() {
  const [globalState] = useGlobal();
  const location = useLocation();
  const categories = useCategories();
  const { data, getCart } = useContext(CartContext);

  useEffect(() => {
    if (globalState.cartID) getCart();
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    setMega(true);
  }, [location]);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [menu, setMenu] = useState(false);
  const [mega, setMega] = useState(true);

  const [isCartOpen, setCartOpen] = useState(false);

  const [search, setSearch] = useState("");

  function afterOpenModal() {
    return;
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      {/* Mobile */}
      <>
        <nav
          className="navbar is-fixed-top is-hidden-desktop is-hidden-tablet"
          style={{ backgroundColor: "#54b6c9" }}>
          <div className="columns is-mobile is-vcentered p-2">
            <div className="column">
              <Hamburger
                toggled={modalIsOpen}
                toggle={setIsOpen}
                size={24}
                color={modalIsOpen ? "#000" : "#FFF"}
              />
            </div>
            <div className="column has-text-centered is-5">
              <Link to="/">
                <StaticImage
                  src="../img/bay-smokes/header-logo.png"
                  alt="Bay Smokes"
                />
              </Link>
            </div>
            <div className="column is-4">
              <div className="control">
                <div
                  className="tag is-danger is-small is-rounded"
                  style={{
                    fontSize: 8,
                    zIndex: 99,
                    position: "absolute",
                    right: 70,
                    top: 7,
                  }}>
                  {data?.cart?.lines?.edges?.length || 0}
                </div>
                <button
                  to="/cart"
                  className="button is-large is-ghost"
                  onClick={() => setCartOpen(true)}>
                  <span className="icon is-large">
                    <FiShoppingCart size={28} color="white" />
                  </span>
                  <span className="is-size-6 has-text-white">
                    $
                    {data?.cart?.estimatedCost?.subtotalAmount?.amount
                      ? Number(
                          data?.cart?.estimatedCost?.subtotalAmount?.amount
                        ).toFixed(2)
                      : "0.00"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </nav>

        <Modal
          closeTimeoutMS={500}
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          // style={customStyles}
          contentLabel="Menu">
          <nav className="navbar is-fixed-top is-hidden-desktop is-hidden-tablet is-shadowless">
            <div
              className="columns is-vcentered is-mobile p-2"
              style={{ marginTop: "-5px" }}>
              <div className="column">
                <Hamburger toggled={modalIsOpen} toggle={setIsOpen} size={24} />
              </div>
              <div className="column is-5">
                <StaticImage
                  src="../img/bay-smokes/header-logo.png"
                  alt="Bay Smokes"
                />
              </div>
              <div className="column is-4">
                <div className="control">
                  <div
                    className="tag is-danger is-small is-rounded"
                    style={{
                      fontSize: 8,
                      zIndex: 99,
                      position: "absolute",
                      right: 70,
                      top: 7,
                    }}>
                    {data?.cart?.lines?.edges?.length || 0}
                  </div>
                  <button
                    to="/cart"
                    className="button is-large is-ghost"
                    onClick={() => setCartOpen(true)}>
                    <span className="icon is-large">
                      <FiShoppingCart size={28} color="white" />
                    </span>
                    <span className="is-size-6 has-text-white">
                      $
                      {data?.cart?.estimatedCost?.subtotalAmount?.amount ||
                        "0.00"}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </nav>
          <br />
          <hr />
          <p className="control has-icons-left">
            <input
              className="input is-black is-rounded mb-5"
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <span className="icon is-left has-text-black">
              <FaSearch />
            </span>
          </p>
          {search.length > 3 ? (
            <div style={{ overflowY: "auto", zIndex: 99999 }}>
              <div>
                <p className="title is-5">Categories</p>

                {categories
                  .filter((category) =>
                    category?.title.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((category) => (
                    <Link
                      key={category?.id}
                      to={`/collections/${category?.handle}`}
                      onClick={() => {
                        setIsOpen(false);
                        setSearch("");
                      }}>
                      <p className="m-2">{category?.title}</p>
                    </Link>
                  ))}
              </div>
              <br />
              <div>
                <p className="title is-5">Products</p>

                {categories
                  .flatMap((category) => category?.products)
                  .filter((product) =>
                    product.title.toLowerCase().includes(search.toLowerCase())
                  )
                  .slice(0, 5)
                  .map((product) => (
                    <>
                      <div className="level is-mobile">
                        <div className="level-left">
                          <Link
                            to={`/products/${product.handle}`}
                            onClick={() => {
                              setIsOpen(false);
                              setSearch("");
                            }}>
                            <figure className="image is-64x64">
                              <GatsbyImage
                                image={
                                  product.featuredMedia?.preview.image.localFile
                                    .childImageSharp.gatsbyImageData
                                }
                              />
                            </figure>
                          </Link>
                        </div>
                        <div className="level-right">
                          <Link
                            to={`/products/${product.handle}`}
                            onClick={() => {
                              setIsOpen(false);
                              setSearch("");
                            }}>
                            <p className="m-2">{product.title}</p>
                          </Link>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          ) : (
            <div style={{ overflowY: "auto" }}>
              <div className="menu-heading">
                <label className="is-6 has-text-blue has-text-weight-bold is-clickable">
                  Shop THCA
                </label>
                <div className="flex">
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/thca-flower">
                    <img src={sthca} alt="Thca Flower" />
                  </Link>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/thca-vape-carts-disposables/">
                    <img src={thacaCarts} alt="THCA carts" />
                  </Link>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/delta9-thc/">
                    <img src={delta9gummies} alt="delta 9 gummies" />
                  </Link>
                </div>
              </div>

              <div className="menutab">
                <input id="tab-5" type="checkbox" />
                <label
                  for="tab-5"
                  className="is-6 has-text-blue has-text-weight-bold is-clickable">
                  Shop THCA
                </label>
                <div className="content">
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/thca-flower">
                    <p className="has-text-black m-2">THCA Flower</p>
                  </Link>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/products/thca-prerolls">
                    <p className="has-text-black m-2">THCA Pre Rolls</p>
                  </Link>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/thca-vape-carts-disposables/">
                    <p className="has-text-black m-2">THCA Vape Cartridges</p>
                  </Link>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/thca-concentrates/">
                    <p className="has-text-black m-2">THCA Concentrates</p>
                  </Link>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/thca-diamonds/">
                    <p className="has-text-black m-2">THCA Diamonds</p>
                  </Link>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/thca-gummies/">
                    <p className="has-text-black m-2">THCA Gummies</p>
                  </Link>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/thca-disposable-vapes/">
                    <p className="has-text-black m-2">THCA Disposable Vapes</p>
                  </Link>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/pages/thca-wholesale/">
                    <p className="has-text-black m-2">THCA Flower Pounds</p>
                  </Link>
                </div>
              </div>

              <div className="menutab">
                <input id="tab-3" type="checkbox" />
                <label
                  for="tab-3"
                  className="is-6 has-text-blue has-text-weight-bold is-clickable">
                  Shop Collabs
                </label>
                <div className="content">
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/wham-by-lil-baby/">
                    <p className="has-text-black m-2">WHAM BY LIL BABY</p>
                  </Link>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/slodown-lodown-by-otf-doodie-lo/">
                    <p className="has-text-black m-2">OTF Doodie Lo</p>
                  </Link>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/hella-slumped/">
                    <p className="has-text-black m-2">Hella Slumped</p>
                  </Link>
                  {/* <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/yodie-land-pack/">
                    <p className="has-text-black m-2">Yodie Land Pack</p>
                  </Link>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/khan-kush-thca-products/">
                    <p className="has-text-black m-2">Khan Kush</p>
                  </Link> */}
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/only-gas-thca-products/">
                    <p className="has-text-black m-2">Only Gas</p>
                  </Link>
                  {/* <Link onClick={() => setIsOpen(false)} to="/collections/">
                    <p className="has-text-black m-2">THCh</p>
                  </Link> */}

                  {/* <Link onClick={() => setIsOpen(false)} to="/collections/">
                    <p className="has-text-black m-2">11 Hydroxy THC</p>
                  </Link> */}
                </div>
              </div>

              <div className="menutab">
                <p className="title is-6 has-text-blue has-text-weight-bold is-clickable">
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/collections/kratom/">
                    Kratom & Mushrooms
                  </Link>
                </p>
              </div>

              <div className="menutab">
                <input id="tab-1" type="checkbox" />
                <label
                  for="tab-1"
                  className="is-6 has-text-blue has-text-weight-bold is-clickable">
                  Learn More
                </label>
                <div className="content">
                  <Link onClick={() => setIsOpen(false)} to="/pages/who-we-are">
                    <p className="has-text-black m-2">About Us</p>
                  </Link>
                  <Link onClick={() => setIsOpen(false)} to="/contact/">
                    <p className="has-text-black m-2">Contact Us</p>
                  </Link>
                  <Link onClick={() => setIsOpen(false)} to="/blogs">
                    <p className="has-text-black m-2">Blog</p>
                  </Link>
                </div>
              </div>

              <div className="menutab">
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/pages/thca-wholesale/">
                  <p
                    className="title is-6 has-text-blue has-text-weight-bold is-clickable"
                    style={{ padding: "15px 0 0 0" }}>
                    Wholesale & Bulk Pounds
                  </p>
                </Link>
              </div>

              <br />

              <div className="menu-btm columns">
                <div className="menu-btm-img flex">
                  <Link onClick={() => setIsOpen(false)} to="/free-gummy">
                    <StaticImage
                      src="../img/bay-smokes/free-gummy.webp"
                      style={{ borderRadius: 10 }}
                      alt="Free Gummy"
                    />
                  </Link>
                  <br />
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="/free-thca-flower-gram-sample">
                    <StaticImage
                      src="../img/bay-smokes/free-gram3.5.webp"
                      style={{ borderRadius: 10 }}
                      alt="Free 3.5 Gram"
                    />
                  </Link>
                </div>

                <Link
                  to="/account"
                  onClick={() => setIsOpen(false)}
                  className="navbar-item is-size-6 has-text-white has-text-weight-bold has-background-blue">
                  <span className="icon">
                    <FaUser />
                  </span>
                  <span>Login</span>
                </Link>
                {/* <div className="social-icon flex">
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="https://www.facebook.com/baysmokes/"
                    target="_blank" rel="noreferrer" 
                  >
                    <img src={fb} alt="Facebook" />
                  </Link>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to="https://www.instagram.com/baysmkez"
                    target="_blank" rel="noreferrer" 
                  >
                    <img src={insta} alt="Instagram" />
                  </Link>
                </div> */}
              </div>
              <br />
              <br />
              <br />
            </div>
          )}
        </Modal>
      </>

      {/* Top Desktop*/}
      <>
        <nav
          className="navbar is-fixed-top is-hidden-mobile"
          style={{ backgroundColor: "#54b6c9" }}>
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <StaticImage
                src="../img/bay-smokes/header-logo.png"
                alt="Bay Smokes"
                width={150}
                loading="eager"
              />
            </Link>
            <div
              className="navbar-burger"
              data-target="navMenu"
              onClick={() => setMenu(!menu)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div
            id="navMenu"
            className={`navbar-menu ${menu ? "is-active" : ""}`}>
            <div className="navbar-start">
              <div className="navbar-item">
                <Search />
              </div>
            </div>

            <Link
              to="/"
              className="navbar-item is-hoverable has-text-white has-text-weight-bold is-size-6">
              Home
            </Link>

            <div className="navbar-item has-dropdown is-hoverable is-mega">
              <div className="navbar-link flex has-text-white has-text-weight-bold is-size-6">
                Shop
              </div>
              {mega && (
                <div
                  id="blogDropdown"
                  className="navbar-dropdown"
                  data-style="width: 18rem;">
                  <div className="container is-fluid m-6">
                    <div className="columns is-centered">
                      <div className="column is-8-desktop is-3-widescreen is-2-fullhd">
                        <div className="columns">
                          <div className="column">
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/thca-flower">
                              <StaticImage
                                src="../img/bay-smokes/shop-thca.webp"
                                style={{ borderRadius: 10 }}
                                alt="Shop THCA"
                              />
                            </Link>

                            <br />
                            <br />
                            <br />

                            <p className="is-size-5">OTHER BRANDS</p>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/wham-by-lil-baby/">
                              <p className="has-text-black m-2">WHAM BY LIL BABY</p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/slodown-lodown-by-otf-doodie-lo/">
                              <p className="has-text-black m-2">
                                OTF Doodie Lo
                              </p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/products/eat-my-shrooms-gummy-cubes/">
                              <p className="has-text-black m-2">
                                Eat My Shrooms | Mushroom Gummies
                              </p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/hella-slumped/">
                              <p className="has-text-black m-2">
                                Hella Slumped
                              </p>
                            </Link>
                            {/* <Link
                              onClick={() => setMega(false)}
                              to="/collections/yodie-land-pack/">
                              <p className="has-text-black m-2">
                                Yodie Land Pack
                              </p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/khan-kush-thca-products/">
                              <p className="has-text-black m-2">Khan Kush</p>
                            </Link> */}
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/only-gas-thca-products/">
                              <p className="has-text-black m-2">Only Gas</p>
                            </Link>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#CCC",
                              width: "1px",
                              marginTop: 10,
                              marginBottom: 20,
                            }}
                          />
                          <div className="column">
                            <p className="is-size-5">SHOP THCA</p>
                            <Link
                              to="/collections/thca-flower/"
                              onClick={() => setMega(false)}>
                              <p className="has-text-black m-2">THCA Flower</p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/products/thca-prerolls/">
                              <p className="has-text-black m-2">
                                THCA Pre Rolls
                              </p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/thca-vape-carts-disposables/">
                              <p className="has-text-black m-2">
                                THCA Vape Cartridges
                              </p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/thca-concentrates/">
                              <p className="has-text-black m-2">
                                THCA Concentrates
                              </p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/thca-diamonds/">
                              <p className="has-text-black m-2">
                                THCA Diamonds
                              </p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/thca-gummies/">
                              <p className="has-text-black m-2">THCA Gummies</p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/thca-disposable-vapes/">
                              <p className="has-text-black m-2">
                                THCA Disposable Vapes
                              </p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/pages/thca-wholesale/">
                              <p className="has-text-black m-2">
                                THCA Flower Pounds
                              </p>
                            </Link>

                            <br />

                            <p className="is-size-5">Shop Delta 9</p>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/delta9-thc/">
                              <p className="has-text-black m-2">
                                Delta 9 Gummies
                              </p>
                            </Link>
                            <br />

                            <p className="is-size-5">Shop CBD</p>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/cbd-flower/">
                              <p className="has-text-black m-2">CBD Flower</p>
                            </Link>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#CCC",
                              width: "1px",
                              marginTop: 10,
                              marginBottom: 20,
                            }}
                          />
                          <div className="column">
                            <p className="is-size-5">PRODUCT TYPE</p>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/vapes-carts-disposables">
                              <p className="has-text-black m-2">Vapes</p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/cannabis-gummy-edibles">
                              <p className="has-text-black m-2">
                                Gummies & Edibles
                              </p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/flower">
                              <p className="has-text-black m-2">Flower</p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/extracts">
                              <p className="has-text-black m-2">
                                Extract & Dabs
                              </p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/kratom">
                              <p className="has-text-black m-2">Kratom</p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/merch-swag">
                              <p className="has-text-black m-2">Merch</p>
                            </Link>
                            <Link
                              onClick={() => setMega(false)}
                              to="/collections/bundles-deals-discounts">
                              <p className="has-text-black m-2">
                                Bundles, Deals & Discounts
                              </p>
                            </Link>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#CCC",

                              width: "1px",
                              marginTop: 10,
                              marginBottom: 20,
                            }}
                          />
                          <div className="column">
                            <Link
                              onClick={() => setMega(false)}
                              to="/free-gummy">
                              <StaticImage
                                src="../img/bay-smokes/free-gummy.webp"
                                style={{ borderRadius: 10 }}
                                alt="Free Gummy"
                              />
                            </Link>
                            <br />
                            <br />
                            <Link
                              onClick={() => setMega(false)}
                              to="/free-thca-flower-gram-sample">
                              <StaticImage
                                src="../img/bay-smokes/free-gram3.5.webp"
                                style={{ borderRadius: 10 }}
                                alt="Free 3.5 Gram"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link has-text-white has-text-weight-bold is-size-6">
                Learn More
              </div>
              <div className="navbar-dropdown">
                <Link to="/pages/who-we-are" className="navbar-item">
                  About Us
                </Link>
                <Link to="/contact" className="navbar-item">
                  Contact Us
                </Link>
                <Link to="/blogs" className="navbar-item">
                  Blog
                </Link>
                <Link to="/affiliate-signup" className="navbar-item">
                  Affiliate Program
                </Link>
                <Link to="/rewards-program" className="navbar-item">
                  Rewards Program
                </Link>
                <Link to="/pages/lab-test-results" className="navbar-item">
                  Lab Test Results
                </Link>
                <a
                  href="https://wholesale.baysmokes.com/pages/wholesale-registration-form"
                  className="navbar-item">
                  Wholesale Registration
                </a>
                <a
                  href="https://wholesale.baysmokes.com/pages/ws-account-login"
                  className="navbar-item">
                  Wholesale Login
                </a>
                <Link to="/pages/thca-wholesale" className="navbar-item">
                  THCa Flower & Diamonds Wholesale
                </Link>
              </div>
            </div>

            <Link
              to="/account"
              className="navbar-item is-size-6 has-text-white has-text-weight-bold">
              <span className="icon">
                <FaUser />
              </span>
              <span>Login</span>
            </Link>

            <div className="navbar-end">
              <div className="navbar-item">
                <div className="field is-grouped">
                  <>
                    <div className="control">
                      <div
                        className="tag is-danger is-small is-rounded"
                        style={{
                          fontSize: 8,
                          zIndex: 99,
                          position: "absolute",
                          right: 70,
                          top: 7,
                        }}>
                        {data?.cart?.lines?.edges?.length || 0}
                      </div>
                      <button
                        to="/cart"
                        className="button is-large is-ghost"
                        onClick={() => setCartOpen(true)}>
                        <span className="icon is-large">
                          <FiShoppingCart size={24} color="white" />
                        </span>
                        <span className="is-size-6 has-text-white">
                          $
                          {data?.cart?.estimatedCost?.subtotalAmount?.amount
                            ? Number(
                                data?.cart?.estimatedCost?.subtotalAmount
                                  ?.amount
                              ).toFixed(2)
                            : "0.00"}
                        </span>
                      </button>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </>

      <Cart isCartOpen={isCartOpen} setCartOpen={setCartOpen} />
    </>
  );
}

const Search = () => {
  const categories = useCategories();
  const products = categories.flatMap((category) => category?.products);

  const [search, setSearch] = useState("");
  const [isOpen, setOpen] = useState(false);

  useEffect(
    () => (search.length > 2 ? setOpen(true) : setOpen(false)),
    [search]
  );

  return (
    <div className={`dropdown ${isOpen && "is-active"}`}>
      <div className="dropdown-trigger">
        <p className="control is-expanded has-icons-left">
          <input
            className="input is-light is-rounded is-fullwidth"
            type="search"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ width: 500 }}
            placeholder="Search for products..."
          />
          <span className="icon is-left">
            <FaSearch />
          </span>
        </p>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content p-5">
          <div>
            <p className="title is-6">Categories</p>

            {categories.filter((category) =>
              category?.title.toLowerCase().includes(search.toLowerCase())
            ).length === 0 && <p>No results found</p>}

            {categories
              .filter((category) =>
                category?.title.toLowerCase().includes(search.toLowerCase())
              )
              .map((category) => (
                <Link
                  key={category?.handle}
                  to={`/collections/${category?.handle}`}
                  onClick={() => {
                    setOpen(false);
                    setSearch("");
                  }}>
                  <p className="m-2 is-size-6 has-text-black">
                    {category?.title}
                  </p>
                </Link>
              ))}
          </div>
          <br />
          <hr className="dropdown-divider" />
          <br />
          <div>
            <p className="title is-6">Products</p>
            {products.filter((product) =>
              product.title.toLowerCase().includes(search.toLowerCase())
            ).length === 0 && <p>No results found</p>}
            {products
              .filter((product) =>
                product.title.toLowerCase().includes(search.toLowerCase())
              )
              .slice(0, 5)
              .map((product) => (
                <Link
                  key={product.handle}
                  to={`/products/${product.handle}`}
                  onClick={() => {
                    setOpen(false);
                    setSearch("");
                  }}>
                  <article className="media">
                    <figure className="media-left">
                      <div className="image is-64x64">
                        <GatsbyImage
                          image={
                            product.featuredMedia?.preview.image.localFile
                              .childImageSharp.gatsbyImageData
                          }
                          alt={product.title}
                        />
                      </div>
                    </figure>
                    <div className="media-content">
                      <div className="content">
                        <p className="title is-6">{product.title}</p>

                        <p className="subtitle is-6">
                          ${product.variants[0].price}
                        </p>
                      </div>
                    </div>
                  </article>
                </Link>
              ))}
          </div>

          {categories.filter((category) =>
            category?.title.toLowerCase().includes(search.toLowerCase())
          ).length > 0 &&
          products.filter((product) =>
            product.title.toLowerCase().includes(search.toLowerCase())
          ).length > 0 ? (
            <>
              <hr className="dropdown-divider" />
              <Link
                to="/collections"
                className="has-text-black"
                onClick={() => {
                  setOpen(false);
                  setSearch("");
                }}
                state={{ search }}>
                View all results
              </Link>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
