exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-affiliate-agreement-js": () => import("./../../../src/pages/affiliate-agreement.js" /* webpackChunkName: "component---src-pages-affiliate-agreement-js" */),
  "component---src-pages-affiliate-signup-js": () => import("./../../../src/pages/affiliate-signup.js" /* webpackChunkName: "component---src-pages-affiliate-signup-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-free-gummy-js": () => import("./../../../src/pages/free-gummy.js" /* webpackChunkName: "component---src-pages-free-gummy-js" */),
  "component---src-pages-free-thca-flower-gram-sample-js": () => import("./../../../src/pages/free-thca-flower-gram-sample.js" /* webpackChunkName: "component---src-pages-free-thca-flower-gram-sample-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pages-lab-test-results-js": () => import("./../../../src/pages/pages/lab-test-results.js" /* webpackChunkName: "component---src-pages-pages-lab-test-results-js" */),
  "component---src-pages-pages-thca-wholesale-js": () => import("./../../../src/pages/pages/thca-wholesale.js" /* webpackChunkName: "component---src-pages-pages-thca-wholesale-js" */),
  "component---src-pages-pages-who-we-are-js": () => import("./../../../src/pages/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-pages-who-we-are-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-rewards-program-js": () => import("./../../../src/pages/rewards-program.js" /* webpackChunkName: "component---src-pages-rewards-program-js" */),
  "component---src-pages-shipping-policy-js": () => import("./../../../src/pages/shipping-policy.js" /* webpackChunkName: "component---src-pages-shipping-policy-js" */),
  "component---src-pages-site-map-js": () => import("./../../../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-collections-cbd-flower-jsx": () => import("./../../../src/templates/collections/cbd-flower.jsx" /* webpackChunkName: "component---src-templates-collections-cbd-flower-jsx" */),
  "component---src-templates-collections-delta-10-jsx": () => import("./../../../src/templates/collections/delta10.jsx" /* webpackChunkName: "component---src-templates-collections-delta-10-jsx" */),
  "component---src-templates-collections-delta-8-carts-jsx": () => import("./../../../src/templates/collections/delta8-carts.jsx" /* webpackChunkName: "component---src-templates-collections-delta-8-carts-jsx" */),
  "component---src-templates-collections-delta-8-disposables-jsx": () => import("./../../../src/templates/collections/delta8-disposables.jsx" /* webpackChunkName: "component---src-templates-collections-delta-8-disposables-jsx" */),
  "component---src-templates-collections-delta-8-edibles-jsx": () => import("./../../../src/templates/collections/delta-8-edibles.jsx" /* webpackChunkName: "component---src-templates-collections-delta-8-edibles-jsx" */),
  "component---src-templates-collections-delta-8-gummies-jsx": () => import("./../../../src/templates/collections/delta8-gummies.jsx" /* webpackChunkName: "component---src-templates-collections-delta-8-gummies-jsx" */),
  "component---src-templates-collections-delta-8-prerolls-jsx": () => import("./../../../src/templates/collections/delta-8-prerolls.jsx" /* webpackChunkName: "component---src-templates-collections-delta-8-prerolls-jsx" */),
  "component---src-templates-collections-delta-8-products-jsx": () => import("./../../../src/templates/collections/delta-8-products.jsx" /* webpackChunkName: "component---src-templates-collections-delta-8-products-jsx" */),
  "component---src-templates-collections-delta-8-tincture-jsx": () => import("./../../../src/templates/collections/delta8-tincture.jsx" /* webpackChunkName: "component---src-templates-collections-delta-8-tincture-jsx" */),
  "component---src-templates-collections-delta-8-vape-pens-jsx": () => import("./../../../src/templates/collections/delta-8-vape-pens.jsx" /* webpackChunkName: "component---src-templates-collections-delta-8-vape-pens-jsx" */),
  "component---src-templates-collections-delta-9-thc-jsx": () => import("./../../../src/templates/collections/delta9-thc.jsx" /* webpackChunkName: "component---src-templates-collections-delta-9-thc-jsx" */),
  "component---src-templates-collections-hella-slumped-jsx": () => import("./../../../src/templates/collections/hella-slumped.jsx" /* webpackChunkName: "component---src-templates-collections-hella-slumped-jsx" */),
  "component---src-templates-collections-live-resin-vs-live-rosin-jsx": () => import("./../../../src/templates/collections/live-resin-vs-live-rosin.jsx" /* webpackChunkName: "component---src-templates-collections-live-resin-vs-live-rosin-jsx" */),
  "component---src-templates-collections-slodown-lodown-by-otf-doodie-lo-jsx": () => import("./../../../src/templates/collections/slodown-lodown-by-otf-doodie-lo.jsx" /* webpackChunkName: "component---src-templates-collections-slodown-lodown-by-otf-doodie-lo-jsx" */),
  "component---src-templates-collections-thca-concentrates-jsx": () => import("./../../../src/templates/collections/thca-concentrates.jsx" /* webpackChunkName: "component---src-templates-collections-thca-concentrates-jsx" */),
  "component---src-templates-collections-thca-diamonds-jsx": () => import("./../../../src/templates/collections/thca-diamonds.jsx" /* webpackChunkName: "component---src-templates-collections-thca-diamonds-jsx" */),
  "component---src-templates-collections-thca-flower-jsx": () => import("./../../../src/templates/collections/thca-flower.jsx" /* webpackChunkName: "component---src-templates-collections-thca-flower-jsx" */),
  "component---src-templates-collections-thca-vape-carts-disposables-jsx": () => import("./../../../src/templates/collections/thca-vape-carts-disposables.jsx" /* webpackChunkName: "component---src-templates-collections-thca-vape-carts-disposables-jsx" */),
  "component---src-templates-collections-thcp-products-for-sale-jsx": () => import("./../../../src/templates/collections/thcp-products-for-sale.jsx" /* webpackChunkName: "component---src-templates-collections-thcp-products-for-sale-jsx" */),
  "component---src-templates-collections-thcv-jsx": () => import("./../../../src/templates/collections/thcv.jsx" /* webpackChunkName: "component---src-templates-collections-thcv-jsx" */),
  "component---src-templates-collections-wham-by-lil-baby-jsx": () => import("./../../../src/templates/collections/wham-by-lil-baby.jsx" /* webpackChunkName: "component---src-templates-collections-wham-by-lil-baby-jsx" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

