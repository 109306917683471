import React, { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { withPrefix, Script } from "gatsby";
import { CartContext } from "context/CartContext";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import useGlobal from "store";

import { TickerBar } from "components/TickerBar";

import { getCheckoutToken } from "utils/getCheckoutToken";

import "styles/all.sass";
import "styles/custom.css";
import "styles/custom2.css";

const Layout = ({ children }) => {
  const [globalState] = useGlobal();
  const [loaded, setLoaded] = useState(false);

  const [getCart, { data }] = useLazyQuery(CART, {
    variables: { id: globalState.cartID },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (loaded) {
      window?.pushowl?.trigger("getCurrentPermission").then((permission) => {
        if (permission !== "default") return;
        window.pushowl.trigger("showWidget", { type: "browserPrompt" });
      });
    }
  }, [loaded]);

  useEffect(() => {
    if (!data?.cart) return;
    window?.pushowl?.trigger("syncCart", {
      checkoutToken: getCheckoutToken(data.cart),
      items: data.cart.lines.edges.map(({ node }) => ({
        variantId: node.merchandise.id.slice(-14),
        productId: node.merchandise.product.id.slice(-13),
        quantity: node.quantity,
      })),
    });
  }, [data]);

  return (
    <>
      <Script
        strategy="idle"
        src={withPrefix("scripts/pushOwl.js")}
        onLoad={() => setLoaded(true)}
      />
      <CartContext.Provider value={{ data, getCart }}>
        <div className="is-fullhd">
          <div id="top">
            {/* <div
              style={{
                position: "fixed",
                top: 0,
                zIndex: 1000,
                width: "100%",
              }}>
              <TickerBar />
            </div> */}
            <Navbar />
            {children}
            <Footer />
          </div>
        </div>
      </CartContext.Provider>
    </>
  );
};

export default Layout;

const CART = gql`
  query Cart($id: ID!) {
    cart(id: $id) {
      id
      checkoutUrl
      createdAt
      updatedAt
      lines(first: 100) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                selectedOptions {
                  name
                  value
                }
                image {
                  id
                  url
                }
                price {
                  amount
                  currencyCode
                }
                product {
                  id
                  title
                }
              }
            }
          }
        }
      }
      attributes {
        key
        value
      }
      estimatedCost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalDutyAmount {
          amount
          currencyCode
        }
      }
    }
  }
`;
